// Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxUpperCaseDirectiveModule } from 'ngx-upper-case-directive';
import { NgxMaskModule } from 'ngx-mask';
import { NgxLowerCaseDirectiveModule } from 'ngx-lower-case-directive';
import { CurrencyMaskModule, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';
import { PaginacaoModule } from './paginacao/paginacao.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SafePipeModule } from 'safe-pipe';
import { CloudImgDirective } from './cloud-img/cloud-img.directive';


export const CustomCurrencyMaskConfig:any = {
    align: "right",
    allowNegative: true,
    //allowZero: true,
    decimal: ",",
    precision: 2,
    prefix: "R$ ",
    suffix: "",
    thousands: "."
};


@NgModule({
    imports: [
        CommonModule,
        FormsModule,    
        PaginacaoModule,
        NgxUpperCaseDirectiveModule,
        NgxLowerCaseDirectiveModule,
        NgxMaskModule.forRoot(),
        NgxSkeletonLoaderModule,
        SafePipeModule,
        CurrencyMaskModule,
        PaginacaoModule,

    ],
    declarations: [
        CloudImgDirective,
    ],
    providers: [
        { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig }
    ],
    exports: [
        CommonModule,
        FormsModule,    
        NgxUpperCaseDirectiveModule,
        NgxLowerCaseDirectiveModule,
        NgxMaskModule,
        NgxSkeletonLoaderModule,
        SafePipeModule,
        CurrencyMaskModule,
        PaginacaoModule,
        CloudImgDirective
    ]
})
export class GerinusToolsModule { }

