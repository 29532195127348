import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-menu',
    templateUrl: './menu-item.component.html',
    styleUrls: ['../sidebar.component.scss'],
})
export class MenuItemComponent {

    @Input() menu;
    
}
