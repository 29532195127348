import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GerinusService } from '../lib/gerinus.service';

@Injectable()
export class EmpresaService extends GerinusService {
 
    constructor(
        public http: HttpClient
    ) {
        super(http);
        this.entityName = "Empresa";
        this.idField = "EMP_CodigoEmpresa";
    }

    public getThemeByURL(url) {
        if (url == '') url = '-1';
        return this.http.post(this.apiURL() + "/api/free/theme",{url:url});
    }
}