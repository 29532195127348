import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GerinusService } from '../lib/gerinus.service';
import swal from 'sweetalert2';

@Injectable()
export class MainService extends GerinusService {
 
    public municipios;
    public total;
    public ppas; 
    public sugestoes; 
    public regioes; 
    public acoes;
    public eixos;
    public latitude; 
    public longitude;
    public localizacao;

    public municipioSelecionado;
    public municipioDados;

    public monitoraMunicipio = new EventEmitter();

    public swal = swal;

    constructor(
        public http: HttpClient
    ) {
        super(http);
    }

    // public class() {
    //     return MainService;
    // }

    public dashboard(municipio) {
        return this.http.get(this.apiURL() + "/api/free/dashboard/" + municipio, this.headers()); 
    }

    public municipioUsuario(municipio) {
        this.municipioSelecionado = municipio;
        
        localStorage.setItem('municipio', JSON.stringify(this.municipioSelecionado));

        this.ppas = null; 
        this.sugestoes = null; 
        this.regioes = null; 
        this.total = null; 
        this.acoes = null; 
        this.eixos = null;
        this.latitude = null;
        this.longitude = null;
        this.localizacao = null;

        this.municipioDados = municipio;
        this.dashboardPresentation(municipio.MUN_CodigoMunicipio);
        this.monitoraMunicipio.emit(municipio);
    }
    public dashboardPresentation(municipio) {
        this.dashboard(municipio).subscribe((response: any) => {
            // this.total = response.data.municipio.TotalMunicipio;

            // this.ppas = response.data.ppa.TotalPPA; 

            this.sugestoes = response.qtdSugestao; 

            this.regioes = response.qtdRegiao; 
            
            this.acoes = response.qtdAcoes;
            
            this.eixos = response.qtdEixo;  

            this.ppas = response.qtdPPA;

            this.total = response.qtdMunicipio;

            this.latitude = response.lat; 

            this.longitude = response.lng;

            this.localizacao = response.regiao;

        });
    }
} 