import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MenuItemComponent } from './sidebar/menu-item/menu-item.component';
import { GerinusToolsModule } from '../app-core/lib/gerinus-tools.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgbModule,
        GerinusToolsModule
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        MenuItemComponent
    ],
    exports: [
        GerinusToolsModule,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        MenuItemComponent
    ]
})
export class ComponentsModule { }
