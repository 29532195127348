import { Component, ViewChild } from '@angular/core';
import { GlobalService } from './app-core/lib/global.service';
import { ToastContainerDirective } from 'ngx-toastr';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'argon-dashboard-angular';

    @ViewChild(ToastContainerDirective, { static: true }) toastContainer: ToastContainerDirective;

    constructor(public global:GlobalService) {
        this.global.toast.overlayContainer = this.toastContainer;
    }
}
