import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NavigationEnd, NavigationStart, Router, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { GlobalService } from './app-core/lib/global.service';
import { EmpresaService } from './app-core/services/empresa.service';

import localePt from '@angular/common/locales/pt';
import {registerLocaleData} from '@angular/common';
import { MainService } from './app-core/services/main.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';


registerLocaleData(localePt)


@NgModule({
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        ComponentsModule,
        NgbModule,
        RouterModule,
        AppRoutingModule,
        ToastrModule.forRoot({
            timeOut: 100000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
        })
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent,
    ],
    providers: [
        MainService,
        ToastrService,
        GlobalService,
        EmpresaService,
        {provide: LOCALE_ID, useValue: 'pt-BR'}
    ],
    bootstrap: [
        AppComponent
    ],
    exports: [

    ]
})
export class AppModule {
    constructor(
        public global: GlobalService,
        public empresa: EmpresaService,
        public router: Router
    ) {
        this.router.events.subscribe((evt) => {
            if (evt instanceof NavigationStart) {
                this.global.startLoading();
            }
            if (evt instanceof NavigationEnd) {
                this.global.stopLoading();
            }
        });

        let host = window.location.hostname;
        if (host === 'localhost') {
            //host = 'gcommerce.com.br';
            //host = 'newlife.gcommerce.com.br';
            //host = 'agendaclass.com.br';
            //host = 'crm.credinowe.com.br'
            host = 'admin.ppafacil.com.br'
        }
        console.log("Host: " + host);
        this.global.theme.loading = true;
        this.empresa.getThemeByURL(host).subscribe((response:any) => {
            console.log("Empresa: ", response);
            if (response.success) {
                GlobalService.companyID = response.companyID;
                this.global.setTheme(response.data);
            }
            else {
                this.global.setTheme(this.global.theme);
            }
            this.global.theme.loading = false;
        });
    }
}
